import React, { lazy, useContext, useEffect, useRef, useState } from 'react';
import { gsap } from "gsap";
import SplitType from 'split-type';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { graphql } from "gatsby";
import parse from 'html-react-parser';
import Layout from "../components/layout"
import Seo from "../components/seo"

// Section Import
import HomeHeroSection from "./sections/home/HomeHeroSection";
import HomeOurWorkSection from "./sections/home/HomeOurWorkSection";
import HomeAboutUsSection from './sections/home/HomeAboutUsSection';
import HomeServicesSection from "./sections/home/HomeServicesSection";
import FAQSection from './sections/FAQSection';
import ClientSection from "./sections/ClientSection";
import BlogSection from "./sections/BlogSection";
import TestimonialSectionV2 from './sections/TestimonialSectionV2';


//ContextApi
import { GlobalDispatchContext, GlobalStateContext } from '../Context/peloader';





const Index = ({ data }) => {
  const mainData = data.allWpPage.nodes[0].homePageACF;
  const sectionRef = useRef();
  const [bgTransition, setBgTransition] = useState('');

  const state = useContext(GlobalStateContext);
  // const [loader, setLoader] =  useState(state.Loader);
 
  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    window.addEventListener('load',()=>{
      window.scrollTo(0, 0);
    })
    let unmounted = false;
    // AOS.init();
    setTimeout(() => {
      if (!unmounted) {

        gsap.registerPlugin(ScrollTrigger);

        //about setcion interactions begin

        //about text reveal
        const aboutHeading = document.querySelector(".about-us-section .about-heading-reveal");
        const aboutPara = document.querySelector(".about-us-section .about-des-reveal");

        

        gsap.set(aboutHeading, { autoAlpha: 1 });
        gsap.set(aboutPara, { autoAlpha: 1 });

        const splitHide = new SplitType(aboutHeading, {type: "words", wordsClass: "hide"});
        const splitHidePara = new SplitType(aboutPara, {type: "words", wordsClass: "hide"});



        const splitHeading = new SplitType(aboutHeading, {
            type: "words,lines",
            wordsClass: "words",
            linesClass: "lines",
        })

        const splitPara = new SplitType(aboutPara, {
          type: "words,lines",
          wordsClass: "words",
          linesClass: "lines",
        })

        const tlHeading = gsap.timeline({
          scrollTrigger:{
            trigger: ".about-us-section",
            ease: "easeInOut",
            // animation: tlHeading,
            start: "top 115%",
            end: "top 115%",
          }
        });
        tlHeading.from(splitHeading.words,{
            // duration: 2.2,
            // duration: 1.2,
            duration: .8,
            yPercent: 100,
            ease: "Power4.easeInOut",
            stagger: {
                amount: 0.1
            },
            // stagger: {
            //   amount: 1
            // },
            rotationX: 0.5,
            rotationY: 0.5,
            z: 0.5
        });

        const tlPara = gsap.timeline({
          scrollTrigger:{
            trigger: ".about-us-section",
            ease: "easeInOut",
            start: "top 115%",
            end: "top 115%",
          }
        });
        tlPara.from(splitPara.words,{
            // duration: 3.2,
            // duration: 2.2,
            duration: 1.2,
            yPercent: 100,
            // delay: 0,
            ease: "Power4.easeInOut",
            stagger: {
                amount: 0.1
            },
            rotationX: 0.5,
            rotationY: 0.5,
            z: 0.5
        });

      //about images reveal
      const gallaryHeight = gsap.timeline({
        scrollTrigger:{
          trigger: ".about-us-section .about-gallary",
        }
      })
      gallaryHeight.to(".about-us-section .about-gallary",{
        height: "auto",
        delay: 1,
        rotationX: 0.5,
        rotationY: 0.5,
        z: 0.5
      })


      const image1 = gsap.timeline({
        scrollTrigger:{
          trigger: ".about-us-section .about-gallary",
        }
      })
      image1.to(".about-us-section .about-gallary .gallary .image-block-1",.4,{
          height: "100%",
          ease: "Expo.easeInOut",
          rotationX: 0.5,
          rotationY: 0.5,
          z: 0.5
      })

      const image2 = gsap.timeline({
        scrollTrigger:{
          trigger: ".about-us-section .about-gallary",
        }
      })

      image2.to(".about-gallary .gallary .image-block-2",.4,{
        height: "100%",
        ease: "Expo.easeInOut",
        rotationX: 0.5,
        rotationY: 0.5,
        z: 0.5
      })
      }
    }, 1000);

    setTimeout(()=>{
      /*
        Services setcion interactions Begin
      */
        const serviceHeading = document.querySelector(".services-section .service-heading-reveal");
      
        gsap.set(serviceHeading, { autoAlpha: 1 });

        const splitServiceHeading = new SplitType(serviceHeading, {
          type: "words,lines",
          wordsClass: "words",
          linesClass: "lines",
        })

        const tlServiceHeading = gsap.timeline({
          scrollTrigger:{
            trigger: ".services-section",
            // ease: "easeInOut",
            // markers: true,
          }
        });
        tlServiceHeading.from(splitServiceHeading.words,{
            // duration: 1.2,
            duration: .8,
            yPercent: 100,
            ease: "Power4.easeInOut",
            // delay: .5,
            // stagger: {
            //     amount: .5
            // },
            stagger: {
                  amount: 0.1
            },
        });


        const item = gsap.timeline({
          scrollTrigger: {
            trigger: ".services-section"
          }
        })
        item.to(".services-section .service-items .item",1.2,{
          y: 0,
          opacity: 1,
          delay: .2,
          ease: "Power4.easeInOut"
        })
      /*
        Services setcion interactions End
      */

      /*
        clients setcion interactions Begin
      */
      const clientsHeading = document.querySelector(".client-section .clients-heading-reveal");
      const clientsPara = document.querySelector(".client-section .clients-para-reveal");

      gsap.set(clientsHeading, { autoAlpha: 1 });
      gsap.set(clientsPara, { autoAlpha: 1 });


      const splitClientsHeading = new SplitType(clientsHeading, {
        type: "words,lines",
        wordsClass: "words",
        linesClass: "lines",
      })

      const splitClientsPara = new SplitType(clientsPara, {
        type: "words,lines",
        wordsClass: "words",
        linesClass: "lines",
      })

      const tlClientsHeading = gsap.timeline({
        scrollTrigger:{
          trigger: ".client-section",
        }
      });
      tlClientsHeading.from(splitClientsHeading.words,{
        // duration: 1,
        // duration:1.2,
        duration: .8,
        yPercent: 100,
        ease: "Power4.easeInOut",
        // stagger: {
        //     amount: .5
        // },
        stagger: {
          amount: 0.1
        },
        rotationX: 0.5,
        rotationY: 0.5,
        z: 0.5
      });

      const tlClientsPara = gsap.timeline({
        scrollTrigger:{
          trigger: ".client-section",
        }
      });
      tlClientsPara.from(splitClientsPara.words,{
        // duration: 1.5,
        duration: .8,
        yPercent: 100,
        ease: "Power4.easeInOut",
        rotationX: 0.5,
        rotationY: 0.5,
        z: 0.5
      });

      const clientsLogo = gsap.timeline({
        scrollTrigger: {
          trigger: ".client-section"
        }
      })
      clientsLogo.to(".client-section .client-items .image-block",1.2,{
        y: 0,
        opacity: 1,
        delay: .5,
        ease: "Power4.easeInOut"
      })
      /*
        clients setcion interactions End
      */

      /*
        Blogs Setcion Interactions Begin
      */

    },300)
    

    // setTimeout(() => {
    //   setLoader(false)
    // },2000) 
     
    
    return () => {
      unmounted = true;
    }
    
  }, []);

  return (
    <>
      {/* {loader
        ? <div className="pre-loader">
            <img src={PreLoader} />
          </div>
        :
        ""
      } */}
      <Layout >
        <Seo title="Home" />
        <div 
          className={"page-wrapper " + bgTransition} 
          id="home-page-wrapper" 
          ref={sectionRef}  
        >
          <HomeHeroSection
            mainTitle={parse(mainData.heroSectionTitle)}
            mainDescription={parse(mainData.heroSectionDescription)}
            details={parse(mainData.heroSectionDetails)}
            image1x={
              {
                sourceUrl: mainData.heroSectionImage1x.sourceUrl,
                altText: mainData.heroSectionImage1x.altText,
                width: mainData.heroSectionImage1x.width,
              }
            }
            image2x={
              {
                sourceUrl: mainData.heroSectionImage2x.sourceUrl,
                altText: mainData.heroSectionImage2x.altText,
                width: mainData.heroSectionImage2x.width,
              }
            }
          />
          <HomeOurWorkSection
            mainTitle={parse(mainData.caseStudySectionTitle)}
            mainDescription={parse(mainData.caseStudySectionDescription)}
            list={mainData.caseStudiesList}
          />
          <HomeAboutUsSection
            mainTitle={parse(mainData.aboutUsTitle)}
            mainDescription={parse(mainData.aboutUsDescription)}
            linkText={parse(mainData.aboutUsLinkText)}
            linkURL={mainData.aboutUsLinkUrl}
            list={mainData.aboutUsList}
            imageSlider={mainData.imageSlider}
            awardMainTitle={parse(mainData.awardSectionTitle)}
            awardMainDescription={parse(mainData.awardSectionDescription)}
            // awardsList={mainData.awardList}
            awardsList={mainData.aboutUsAwardsList}
            image11x={
              {
                sourceUrl: mainData.aboutUsImage11x.sourceUrl,
                altText: mainData.aboutUsImage11x.altText,
                width: mainData.aboutUsImage11x.width,
              }
            }
            image12x={
              {
                sourceUrl: mainData.aboutUsImage12x.sourceUrl,
                altText: mainData.aboutUsImage12x.altText,
                width: mainData.aboutUsImage12x.width,
              }
            }
            image21x={
              {
                sourceUrl: mainData.aboutUsImage21x.sourceUrl,
                altText: mainData.aboutUsImage21x.altText,
                width: mainData.aboutUsImage21x.width,
              }
            }
            image22x={
              {
                sourceUrl: mainData.aboutUsImage22x.sourceUrl,
                altText: mainData.aboutUsImage22x.altText,
                width: mainData.aboutUsImage22x.width,
              }
            }
            image31x={
              {
                sourceUrl: mainData.aboutUsImage31x.sourceUrl,
                altText: mainData.aboutUsImage31x.altText,
                width: mainData.aboutUsImage31x.width,
              }
            }
            image32x={
              {
                sourceUrl: mainData.aboutUsImage32x.sourceUrl,
                altText: mainData.aboutUsImage32x.altText,
                width: mainData.aboutUsImage32x.width,
              }
            }
            image41x={
              {
                sourceUrl: mainData.aboutUsImage41x.sourceUrl,
                altText: mainData.aboutUsImage41x.altText,
                width: mainData.aboutUsImage41x.width,
              }
            }
            image42x={
              {
                sourceUrl: mainData.aboutUsImage42x.sourceUrl,
                altText: mainData.aboutUsImage42x.altText,
                width: mainData.aboutUsImage42x.width,
              }
            }
          />
          <HomeServicesSection
            mainTitle={parse(mainData.ourServicesTitle)}
            list={mainData.ourServiceList}
          />
          <FAQSection
            mainTitle={parse(mainData.faqSectionTitle)}
            list={mainData.faqSectionList}
            image1x={
              {
                sourceUrl: mainData.faqSectionImage1x.sourceUrl,
                altText: mainData.faqSectionImage1x.altText,
                width: mainData.faqSectionImage1x.width,
              }
            }
            image2x={
              {
                sourceUrl: mainData.faqSectionImage2x.sourceUrl,
                altText: mainData.faqSectionImage2x.altText,
                width: mainData.faqSectionImage2x.width,
              }
            }
            VideoUrl={mainData.faqSectionVideourl}
            preTitle={parse(mainData.faqSectionPreTitle)}
            mainDescription={parse(mainData.faqSectionDescription)}
          />
          <ClientSection
            mainTitle={parse(mainData.clientSectionTitle)}
            mainDescription={parse(mainData.clientSectionDescription)}
            list={mainData.clientSectionList}
            clients={mainData.clientSectionImage}
          />
          <TestimonialSectionV2 
            mainTitle={parse(mainData.testimonialSectionV2Title)}
            image1x={
              {
                sourceUrl: mainData.testimonialSectionV2Image1x.sourceUrl,
                altText: mainData.testimonialSectionV2Image1x.altText,
                width: mainData.testimonialSectionV2Image1x.width,
              }
            }
            image2x={
              {
                sourceUrl: mainData.testimonialSectionV2Image2x.sourceUrl,
                altText: mainData.testimonialSectionV2Image2x.altText,
                width: mainData.testimonialSectionV2Image2x.width,
              }
            }
            VideoUrl={mainData.testimonialSectionV2Videourl}
            list={mainData.testimonialSectionV2List}
          />
          <BlogSection />
        </div>
      </Layout>
     </>
  )
}

export default Index;

export const indexQuery = graphql`
{
  allWpPage(filter: {slug: {eq: "home"}}) {
    nodes {
      homePageACF {
        heroSectionTitle
        heroSectionDescription
        heroSectionImage1x {
          sourceUrl
          altText
          width
        }
        heroSectionImage2x {
          sourceUrl
          altText
          width
        }
        heroSectionDetails
        caseStudySectionTitle
        caseStudySectionDescription
        caseStudiesList {
          title
          mainDescription
          description
          url
          fieldGroupName
          tag
          thumbnail1x {
            altText
            width
            sourceUrl
          }
          thumbnail2x {
            altText
            width
            sourceUrl
          }
          thumbnailAnimation
        }
        processSectionTitle
        processSectionDescription
        processSectionImage1x {
          altText
          sourceUrl
          width
        }
        processSectionImage2x {
          altText
          sourceUrl
          width
        }
        processSectionVideourl
        aboutUsTitle
        aboutUsDescription
        aboutUsLinkText
        aboutUsLinkUrl
        aboutUsList {
          label
          value
        }
        aboutUsAwardsList {
          image {
            altText
            sourceUrl
            width
          }
        }
        aboutUsImage11x {
          altText
          sourceUrl
          width
        }
        aboutUsImage12x {
          altText
          sourceUrl
          width
        }
        aboutUsImage21x {
          altText
          sourceUrl
          width
        }
        aboutUsImage22x {
          altText
          sourceUrl
          width
        }
        aboutUsImage31x {
          altText
          sourceUrl
          width
        }
        aboutUsImage32x {
          altText
          sourceUrl
          width
        }
        aboutUsImage41x {
          altText
          sourceUrl
          width
        }
        aboutUsImage42x {
          altText
          sourceUrl
          width
        }
        imageSlider {
          image1x {
            altText
            sourceUrl
            width
          }
          image2x {
            altText
            sourceUrl
            width
          }
        }
        awardSectionDescription
        awardSectionTitle
        awardList {
          awardName
          awardBy
          awardImage {
            altText
            sourceUrl
            width
          }
        }
        ourServicesTitle
        ourServiceList {
          title
          description
          bulletPoints {
            description
          }
        }
        faqSectionTitle
        faqSectionImage1x {
          altText
          sourceUrl
          width
        }
        faqSectionImage2x {
          altText
          sourceUrl
          width
        }
        faqSectionVideourl
        faqSectionPreTitle
        faqSectionDescription
        faqSectionList {
          title
          content
        }
        clientSectionTitle
        clientSectionDescription
        clientSectionList {
          image {
            altText
            sourceUrl
            width
          }
          description
        }
        clientSectionImage {
          altText
          sourceUrl
          width
        }
        testimonialSectionTitle
        testimonialSectionDescription
        testimonialSectionDesignation
        testimonialSectionName
        testimonialSectionHeadshot1x {
          altText
          width
          sourceUrl
        }
        testimonialSectionHeadshot2x {
          altText
          width
          sourceUrl
        }
        testimonialSectionLogo {
          altText
          width
          sourceUrl
        }

        testimonialSectionV2Title
        testimonialSectionV2Image1x {
          altText
          sourceUrl
          width
        }
        testimonialSectionV2Image2x {
          altText
          sourceUrl
          width
        }
        testimonialSectionV2Videourl
        testimonialSectionV2List {
          qouteIcon {
            altText
            sourceUrl
            width
          }
          message
          clientImage {
            altText
            sourceUrl
            width
          }
          clientName
          company
        }
      }
    }
  }
}`